import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["px-2 py-1 text-xs border rounded-md", [
       { 'text-blue-600 bg-blue-200 border-blue-300': _ctx.erpSyncStatus === 'init' && !_ctx.overrideStatus},
       { 'text-orange bg-yellow-100 border-orange': _ctx.erpSyncStatus === 'pending' || _ctx.overrideStatus },
       { 'text-green-600 bg-green-100 border-green-600': _ctx.erpSyncStatus === 'synced' && !_ctx.overrideStatus },
       { 'text-red-600 bg-red-100 border-red-600': _ctx.erpSyncStatus === 'failed' && !_ctx.overrideStatus }
    ]])
  }, _toDisplayString(_ctx.overrideStatus ? _ctx.$t('orders.erpSyncStatus.pending') :  _ctx.$t(`orders.erpSyncStatus.${_ctx.erpSyncStatus ?? 'not_set' }`)), 3))
}